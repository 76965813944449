import { useModal } from '@ebay/nice-modal-react'

import { Error, Row, Spacer, Footer } from 'components/ui/form/Form'
import TextInput from 'components/ui/form/TextInput'
import RegTextInput from 'components/ui/form/RegTextInput'
import { Button } from 'components/gsys-ui'
import RadioButton from 'components/ui/form/RadioButton'
import ImagePicker from 'components/ui/form/ImagePicker'
import PartsRadioButton from './PartsRadioButton'
import ConsumablesRadioButton from './ConsumablesRadioButton'
import { useFormikContext } from 'formik'
import { useContext } from 'react'
import { GlobalContext } from 'context/GlobalContext'
import Dropdown from 'components/ui/Dropdown'

const EnquiryForm = ({ isSubmitting, handleSubmit, error }) => {
  const modal = useModal()
  const { setFieldValue, values } = useFormikContext()
  const { quickLaunchParts } = useContext(GlobalContext)

  const quickLaunchDropdown = quickLaunchParts.map((p, ind) => ({ id: ind, name: p }))

  return (
    <>
      <Error>{error}</Error>
      <Row>
        <RegTextInput
          name="Reg"
          placeholder="Enter reg"
          size={2}
          disabled={values.Consumables === 'Consumables'}
        />

        <div className="flex justify-between items-center w-full">
          <PartsRadioButton name="Consumables" label="Parts" value="Parts" />
          <ConsumablesRadioButton name="Consumables" label="Consumables" value="Consumables" />
        </div>
      </Row>
      <Spacer />
      <Row>
        <div className="flex items-end w-full">
          <div className="flex-1">
            <TextInput name="Parts" label="Part details" />
          </div>
          <div className="ml-2 flex-0">
            <Dropdown
              values={quickLaunchDropdown}
              label="Use part shortcut"
              onClick={(v) => setFieldValue('Parts', v.name)}
            />
          </div>
        </div>
      </Row>
      <Spacer />
      <Row>
        <TextInput name="Notes" label="Notes" />
      </Row>
      <Spacer />
      <Row>
        <div className="flex items-center w-full">
          <div className="flex flex-1 justify-between items-center w-full">
            <RadioButton name="Status" label="Quote" value="Waiting" />
            <RadioButton name="Status" label="Order" value="Order" />
          </div>
          <div className="flex-1 w-full">
            <ImagePicker name="Image" />
          </div>
        </div>
      </Row>
      <Spacer />
      <Footer>
        <Button variant="white" onClick={modal.remove}>Cancel</Button>
        <Button onClick={handleSubmit} loading={isSubmitting}>Submit</Button>
      </Footer>
    </>
  )
}

export default EnquiryForm