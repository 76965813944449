import clsx from 'clsx'

const Card = ({ children, ...props }) => (
  <div
    className={clsx(
      'bg-white rounded-md w-full border border-gray-300 overflow-hidden',
      {
        'p-5': props.pad ?? true,
        'hover:shadow-md transition-shadow cursor-pointer': !!props.hoverable
      },
      props.className
    )}
  >
    {children}
  </div>
)

const Title = ({ Icon, children }) => (
  <div className="flex items-center px-8 pt-8 pb-4">
    <Icon className="mr-3 w-7 h-7 flex-0" />
    <div className="flex flex-1 justify-between items-center text-xl font-semibold">{children}</div>
  </div>
)

const Body = ({ children }) => (
  <div className="px-8 pb-8">
    {children}
  </div>
)

Card.Title = Title
Card.Body = Body

export default Card