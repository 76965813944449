import { createContext, useEffect, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [cinfo, setCinfo] = useState(null)
  const [currencySymbol, setCurrencySymbol] = useState(null)
  const [uinfo, setUinfo] = useState(null)
  const [brandingBlob, setBrandingBlob] = useState(null)
  const [quickLaunchParts, setQuickLaunchParts] = useLocalStorageState('quickLaunchParts', { defaultValue: [] })
  const [apir, setApir] = useState(null)
  const version = "1.1.2"

  useEffect(() => {
    if (!cinfo) return
    cinfo.CurrencyCode == 'GBP' ? setCurrencySymbol('£') : setCurrencySymbol('€')
  }, [cinfo])

  const addQuickLaunchPart = (part) => {
    if (quickLaunchParts.includes(part)) return false

    const newParts = [
      ...quickLaunchParts,
      part
    ]

    setQuickLaunchParts(newParts)
  }

  const deleteQuickLaunchPart = (part) => {
    let newParts = quickLaunchParts.filter((p) => p !== part)
    setQuickLaunchParts(newParts)
  }

  const value = {
    cinfo, setCinfo,
    uinfo, setUinfo,
    currencySymbol,
    brandingBlob, setBrandingBlob,
    quickLaunchParts, addQuickLaunchPart, deleteQuickLaunchPart,
    version,
    apir, setApir
  }

  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  )
}